import React from 'react';
import profilePic from '../public/imgs/profile.jpg';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className='container-fluid homepage'>
      <div className="container vh-100 d-flex align-items-center">
        <div className="row w-100">
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <h1 className="display-2 name-text">Aidan Day</h1>
            <p className="lead mt-4 paragraph-text">
              I am a software engineer with a passion for building high-quality, scalable systems. I am an effective collaborator, able to work well in team environments and communicate effectively with both technical and non-technical stakeholders. In my free time, I enjoy staying up to date with the latest developments in cloud architecture and exploring new technologies in this space.
            </p>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={profilePic} alt="Your Name" className="img-fluid square-image" />
          </div>
        </div>
      </div>
    </div>
    );
}

export default HomePage;
