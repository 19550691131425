// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Optional for custom styling

const Footer = () => {
  return (
    <footer>
      <div className="social-links ">
        <a href="https://github.com/day22" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="https://linkedin.com/in/yourusername" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://www.instagram.com/aidan_day_/" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
    </footer>
  );
}

export default Footer;
